import * as React from "react";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack"
import { StaticImage } from "gatsby-plugin-image"

import "./InvestorPage.css"
import { AnimatedLink } from "../../components/Links";
import { SectionBreak } from "../../components";

function InvestorPage(){


    return(
        <Container id="investorContainer">
            <Stack direction="column" spacing={5}>
            <Stack direction="row" spacing={10}>
                <StaticImage src="../../icons/inference_logo.png" style={{"height": "10vw", "width": "10vw"}} alt="Inference Health Logo" />
                <Stack direction="column" spacing={2}>
                <h4>Inference Health</h4>
                <p>I started <AnimatedLink to="https://inferencehealth.com">Inference Health</AnimatedLink> back in 2021. Ping me for more!</p>
                <p>We&apos;re a <AnimatedLink to="https://www.techstars.com/newsroom/unitedhealthcare-accelerator-powered-by-techstars-class-of-2021">Techstars</AnimatedLink> company building a transparent healthcare economy.</p>
                </Stack>
            </Stack>


            <SectionBreak text="I've worked at:"/>

            <Stack direction="row" spacing={4} justifyContent="center">
                <StaticImage src="../../icons/logos/Moffitt_logo.png" height={100} alt="Moffitt Cancer Center" />
                <StaticImage src="../../icons/logos/NCI_logo.png" height={100} alt="National Cancer Center" />
                <StaticImage src="../../icons/logos/SHC_logo.png" height={100} alt="Stanford Health Center" />
                <StaticImage src="../../icons/logos/Stanford_logo.png" height={100} alt="Stanford" />
                <StaticImage src="../../icons/logos/Samsung_logo.webp" height={100} alt="Samsung Semiconductor" />
                <StaticImage src="../../icons/logos/unifyid_logo.png" height={100} alt="UnifyID" />
                <StaticImage src="../../icons/logos/twosig_logo.png" height={100} alt="Two Sigma" />
            </Stack>

            <SectionBreak text="Some awards:"/>

            <Stack direction="row" spacing={4} justifyContent="center">
                <StaticImage src="../../icons/awards/bioOly.png" height={100} alt="Biology Olympiad" />
                <StaticImage src="../../icons/awards/RegeneronSTS.png" height={100} alt="Regeneron Science Talent Search" />
                <StaticImage src="../../icons/awards/Scholastic.jpg" height={100} alt="Scholastic Writing Award" />
            </Stack>

            <SectionBreak text={["Check out my ", <AnimatedLink to="/category/projects">projects</AnimatedLink>, " for more!"]}/>

            </Stack>
        </Container>
    );
}

export default InvestorPage;